// extracted by mini-css-extract-plugin
export var background = "_2-0";
export var buttonPrimary = "_2-1";
export var container = "_2-2";
export var feature = "_2-5";
export var featureBody = "_2-6";
export var featureIcon = "_2-7";
export var featureSummary = "_2-9";
export var featureTitle = "_2-8";
export var modal = "_2-f";
export var modalAction = "_2-e";
export var modalCloseButton = "_2-d";
export var modalFeatures = "_2-4";
export var modalFooter = "_2-a";
export var modalImageLogo = "_2-b";
export var modalLogo = "_2-c";
export var redoModal = "_2-3";