import { useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import {
  getAutocheck,
  ShopifyExtensionClient,
} from "@redotech/redo-shopify-extension/client/shopify-extension-client";
import { ModalContent } from "@redotech/redo-shopify-extension/modal";
import { memo } from "react";
import { REDO_API_URL } from "./config";
import * as infoCss from "./info.module.css";

const widgetId = new URLSearchParams(location.search).get("widgetId");
if (!widgetId) {
  throw new Error("Missing widgetId");
}

export const Info = memo(function Info() {
  const redoClient = new RedoClient(REDO_API_URL);
  const widgetClient = new ShopifyExtensionClient(redoClient, widgetId);

  const autocheck = useLoad(
    (signal) => getAutocheck(widgetClient, { signal, customerId: null }),
    [],
  );

  if (!autocheck.value) {
    return null;
  }

  return (
    <div className={infoCss.info}>
      <ModalContent
        modalLogo={autocheck.value.modalLogo}
        modalTextAdjustments={autocheck.value.modalTextAdjustments}
      />
    </div>
  );
});
